import React from "react";
import { Link } from "gatsby";
import "../../styles/page/industries_page.scss";

/**
 * This component renders a rating testimonial card in dicom-nifti
 * @param {string} {heading} data from prismic
 * @param {string} {description} data from prismic
 * @param {object} {icon} data from prismic
 * @param {string} {featureText} data from prismic
 * @param {string} {learnMoreLink} link comping from prismic
 * @author Waqas Ahmad
 * @returns JSX.Element
 */
const TestimonialCardSection = ({
  heading,
  description,
  icon,
  featureText,
  learnMoreLink,
  featureIcon,
}) => {
  return (
    <main className="lg:p-5 shadow-header px-4 py-6 sm:px-7 sm:py-7 lg:px-7 lg:py-7.5 lg:max-w-md max-w-full mx-auto flex flex-col overflow-hidden rounded-2xl bg-white w-full justify-between">
      <section>
        {icon?.url && (
          <div className="flex lg:pt-0 pt-2 lg:pb-11 pb-9 items-center gap-x-4">
            {/* <img
              src={whiteEncordIcon}
              width={0}
              height={0}
              alt={""}
              loading="lazy"
              className="w-32 h-auto"
            /> */}
            {/* <span className="text-lg text-white">X</span> */}
            <img
              src={icon?.url}
              width={icon?.dimensions?.width || 0}
              height={icon?.dimensions?.height || 0}
              alt={icon?.alt || "img"}
              loading="lazy"
              className={`max-w-min h-auto -m-6 pb-3 sm:pr-0 sm:w-auto w-full`}
            />
          </div>
        )}

        {featureText ? (
          <div className="flex lg:gap-x-4 gap-x-2 items-center">
            <div className="lg:py-5 sm:py-1.5 py-3 lg:px-2 sm:px-1.5 px-1 text-base sm:text-2.5xl -tracking-tighter_3 text-white sm:font-semibold font-medium text-center feature_text rounded-sm max-w-[80px]">
              {featureText}
            </div>
            <h4 className="text-white text-2xl lg:max-w-[232px] w-full tracking-tighter_1 font-semibold text-left lg:min-h-[56px]">
              {heading}
            </h4>
          </div>
        ) : featureIcon ? (
          <div className="flex lg:gap-x-4 gap-x-2  items-center">
            <img
              src={featureIcon?.url}
              width={0}
              height={0}
              alt={featureIcon?.alt || "img"}
              loading="lazy"
              className="lg:w-16 w-11 h-auto"
            />
            <h4 className="text-white text-2xl lg:max-w-[232px] w-full tracking-tighter_3 font-semibold text-left lg:min-h-[56px]">
              {heading}
            </h4>
          </div>
        ) : (
          <h4 className="text-white text-2xl lg:max-w-[232px] w-full font-semibold text-left tracking-tighter_3 lg:min-h-[56px]">
            {heading}
          </h4>
        )}
        <p className="text-gray-600 text-base font-normal text-left lg:pt-6 lg:pb-4 sm:pt-9 sm:pb-4 pb-5 pt-6">
          {description}
        </p>
      </section>

      {learnMoreLink && (
        <div className="flex justify-start gap-x-1 items-center">
          <Link
            to={learnMoreLink}
            className="font-medium text-[#C5BBFF] flex items-center w-max sm:text-lg text-base"
          >
            Learn more
          </Link>
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0259 8.28787L4.53439 13.3581C4.51743 13.3715 4.49705 13.3798 4.47558 13.382C4.45411 13.3843 4.43243 13.3805 4.41304 13.371C4.39364 13.3616 4.3773 13.3468 4.36591 13.3285C4.35452 13.3101 4.34853 13.289 4.34863 13.2674V12.1543C4.34863 12.0837 4.38175 12.016 4.43647 11.9728L9.62045 7.92499L4.43647 3.87715C4.38031 3.83395 4.34863 3.76627 4.34863 3.69571V2.5826C4.34863 2.48612 4.45951 2.43284 4.53439 2.49188L11.0259 7.56211C11.0811 7.60515 11.1257 7.6602 11.1564 7.72308C11.1871 7.78596 11.203 7.85501 11.203 7.92499C11.203 7.99496 11.1871 8.06401 11.1564 8.1269C11.1257 8.18978 11.0811 8.24483 11.0259 8.28787Z"
              fill="#C5BBFF"
            />
          </svg>
        </div>
      )}
    </main>
  );
};

export default TestimonialCardSection;
