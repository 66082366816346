import { graphql } from "gatsby";
import React from "react";
import { DicomFooter, HealthcareHero } from "../assets/backgrounds";
import NewHomeLeadFormWithAPIForm from "../components/NewHomeLeadFormWithAPI";
import TrustedBrands from "../components/TrustedBrands";
import VideoComponent from "../components/VideoComponent";
import BetterModels from "../components/dicom_page/BetterModels";
import EnterpriseCard from "../components/dicom_page/EnterpriseCard";
import TestimonialCardSection from "../components/dicom_page/TestimonialCardSection";
import SideCard from "../components/home_Pages/SideCard";
import CardSection from "../components/industries_Pages/CardSection";
import Layout from "../components/layout";
import RowsSection from "../components/sar_Pages/RowsSection";
import SEO from "../components/seo";
import { pickSlice } from "../utilities/helpers";
import HeroPoster from "../assets/dicom-hero-poster.png";
import CustomLink from "../components/CustomLink";
import "../styles/page/dicom_page.scss";
import LogoMarquee from "../components/LogoMarquee";
import { getImage } from "gatsby-plugin-image";


const DicomNifti = ({ location, data }) => {
  const trusted_brands_list =
    data?.allPrismicTrustedBrandsLogos &&
    !!data.allPrismicTrustedBrandsLogos?.nodes.length &&
    data.allPrismicTrustedBrandsLogos?.nodes[0]?.data?.body[0];

  const _data = data?.prismicDicomNifti?.data || {};
  const [repeatable_cards] = pickSlice(_data, "repeatable_cards");
  const [enterprise_cards] = pickSlice(_data, "enterprise_cards");
  const [page_section] = pickSlice(_data, "page_section");
  const [feature_cards] = pickSlice(_data, "feature_cards");
  const [g2_rating] = pickSlice(_data, "g2_rating");
  const [better_model] = pickSlice(_data, "better_model");

  return (
    <Layout location={location} theme="purple">
      <main className="overflow-hidden bg">
        <div className="relative">
          <article className="px-5 pt-16 mx-auto max-w-7xl md:pt-32 xl:px-0 sm:px-6">
            <section className="relative">
              <div className="absolute z-0 hidden lg:top-1/3 -bottom-2/3 lg:right-5 sm:block"></div>
              <section className="relative flex flex-col max-w-4xl px-2 mx-auto mb-8 sm:mb-12 lg:mt-0 mt-14 lg:px-0 sm:px-10">
                <div
                  dangerouslySetInnerHTML={{ __html: _data?.page_heading.html }}
                  className="h1_gradient_dicom text-2.5xl sm:text-3.75xl lg:leading-tight tracking-tighter_3 lg:text-4.5xl font-semibold text-center lg:px-10 sm:px-16 px-7 lg:mb-9 sm:mb-7 mb-5"
                />

                <p className="mb-10 text-base text-center tracking-tighter_1 lg:text-xl lg:px-0 sm:px-14 sm:mb-11">
                  {_data?.page_description?.text}
                </p>
                <section className="flex justify-center">
                  <NewHomeLeadFormWithAPIForm
                    location={location}
                    btnText="Book a demo"
                    isCustomBtnBg={true}
                    btnColor="dicom-btn border-0 px-5 h-[50px]"
                  />
                </section>
              </section>

              <section className="lg:max-h-[600px] lg:min-h-[510px] max-w-[1000px] mx-auto lg:rounded-3xl sm:rounded-xl rounded-lg relative">
                {_data?.hero_section_video?.url ? (
                  <VideoComponent
                    poster={HeroPoster}
                    loop
                    autoPlay
                    muted
                    className="mx-auto max-h-[600px] lg:rounded-3xl sm:rounded-xl rounded-lg"
                  >
                    <source
                      src={_data?.hero_section_video?.url}
                      type="video/mp4"
                    />
                  </VideoComponent>
                ) : (
                  <>
                    {_data?.hero_section_image?.url ? (
                      <img
                        src={_data?.hero_section_image?.url}
                        loading="eager"
                        width={0}
                        height={0}
                        alt="stats"
                        className="max-w-4xl mx-auto w-full max-h-[600px] lg:rounded-3xl sm:rounded-xl rounded-lg"
                      />
                    ) : (
                      <section className="mt-20 2xl:mt-28 w-full h-[512px] rounded-xl shadow-card" />
                    )}
                  </>
                )}
              </section>

              {trusted_brands_list && (
                <section className="pt-16 pb-10 mx-auto space-y-1 sm:pt-20 lg:pb-10 sm:pb-22 img_grayscale_opacity">
                  <p className="text-center uppercase text-xs sm:text-base font-medium text-white sm:tracking-[0.1em] tracking-[0.3px]">
                    {trusted_brands_list?.primary?.label?.text}
                  </p>
                  <LogoMarquee
                    logoArray={trusted_brands_list}
                    logoBackground=""
                    logo_bg
                  />
                </section>
              )}
            </section>
          </article>

          {(repeatable_cards || g2_rating) && (
            <section className="relative">
              {repeatable_cards && (
                <section className="relative">
                  <article className="px-5 pt-12 mx-auto max-w-7xl xl:px-0 sm:px-6 sm:pt-0">
                    <div className="pb-12 mx-auto sm:pb-16 lg:max-w-6xl lg:px-14">
                      <h2 className="text-center text-[#C5BBFF] uppercase sm:tracking-[0.1em] tracking-[0.78px] font-medium mb-6 sm:mb-4 text-xs sm:text-base">
                        {repeatable_cards?.primary?.section_label?.text}
                      </h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            repeatable_cards?.primary?.section_heading?.html,
                        }}
                        className="h3_gradient_dicom text-2xl sm:text-3.25xl sm:leading-10 tracking-tighter_3 font-semibold text-center lg:px-10 sm:px-3 px-5 mx-auto max-w-xs sm:max-w-full"
                      />
                      {repeatable_cards?.primary?.section_link?.url && (
                        <section className="flex justify-center lg:pt-7.5 sm:pt-8 pt-6 lg:mb-20 sm:mb-14 mb-7">
                          <CustomLink
                            href={repeatable_cards?.primary?.section_link?.url}
                            target="_blank"
                            rel="Try Encord today noreferrer"
                            className={`text-white btn-dicom-nifti dicom-btn`}
                          >
                            Book a demo
                          </CustomLink>
                        </section>
                      )}
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 lg:gap-x-7 lg:gap-y-6 sm:gap-x-4">
                        {repeatable_cards?.items.map((item, index) => (
                          <SideCard
                            key={index}
                            data={item}
                            cardClass="side_card_bg lg:gap-7 sm:gap-3 gap-y-2"
                            headingClass="text-gray-1500 text-2xl"
                            descriptionClass="text-gray-1500 lg:pt-0 pt-0.5"
                            imageClasses="rounded-lg"
                            containerClasses="card_padding"
                          />
                        ))}
                      </div>
                    </div>
                  </article>
                </section>
              )}
              {g2_rating && (
                <article className="relative px-5 mx-auto max-w-7xl xl:px-5 md:px-6 sm:px-0 testimonial_gradient">
                  <section className="py-12 xl:px-0 sm:px-6 sm:py-16">
                    <h2 className="text-center text-[#C5BBFF] uppercase sm:tracking-[0.1em] tracking-[0.78px] font-medium mb-6 sm:mb-4 text-xs sm:text-base">
                      {g2_rating?.primary?.section_label?.text}
                    </h2>
                    <h3 className="text-2xl sm:text-3.25xl sm:leading-11 tracking-tighter_3 font-semibold text-center lg:px-10 sm:px-3 mx-auto max-w-[275px] sm:max-w-xl lg:max-w-full">
                      {g2_rating?.primary?.section_heading?.text}
                    </h3>
                    {g2_rating?.primary?.section_link?.url && (
                      <section className="flex justify-center lg:pt-7.5 sm:pt-8 pt-6 lg:mb-20 sm:mb-16 mb-7">
                        <CustomLink
                          href={g2_rating?.primary?.section_link?.url}
                          target="_blank"
                          rel="Try Encord today noreferrer"
                          className={`text-white btn-dicom-nifti dicom-btn`}
                        >
                          Try Encord today
                        </CustomLink>
                      </section>
                    )}
                    <section className="flex flex-col justify-center lg:flex-row lg:gap-x-7 sm:gap-y-4 gap-y-2.5 max-w-7xl testimonial_bg">
                      {g2_rating?.items?.map((item, index) => {
                        return (
                          <TestimonialCardSection
                            key={index}
                            heading={item?.section_heading?.text}
                            description={item?.section_description?.text}
                            icon={item?.icon}
                            featureText={item?.feature_text?.text}
                            learnMoreLink={item?.learn_more_link?.url}
                            featureIcon={item?.feature_icon}
                          />
                        );
                      })}
                    </section>
                  </section>
                </article>
              )}
            </section>
          )}

          {feature_cards && (
            <article className="relative px-5 pb-1 mx-auto lg:pt-9 lg:pb-16 sm:pb-20 max-w-7xl xl:px-0 md:px-6 sm:px-0">
              <div className="relative">
                <h3 className="text-2xl sm:text-3.25xl sm:leading-11 tracking-tighter_3 font-semibold text-center lg:px-10 sm:px-3 lg:mb-16 sm:mb-12 mb-11 mx-auto max-w-[275px] sm:max-w-sm lg:max-w-full">
                  {feature_cards?.primary?.section_heading?.text}
                </h3>
                <section className="flex flex-wrap justify-center my-11 sm:my-12 lg:gap-x-7 lg:gap-y-2.5 sm:gap-y-4 sm:gap-x-4 gap-y-3.5 max-w-7xl lg:my-16 feature_card_bg">
                  {feature_cards?.items?.map((item, index) => {
                     const featureCardImage = getImage(item?.card_image);
                    return (
                      <CardSection
                        key={index}
                        imgAlt={""}
                        imgSrc={featureCardImage}
                        videoSrc={item?.card_video?.url}
                        imageWidth={item?.card_image?.dimensions?.width}
                        imageHeight={item?.card_image?.dimensions?.height}
                        heading={item?.card_heading?.text}
                        isCustom="line min-h-[6px]"
                      />
                    );
                  })}
                </section>
              </div>
            </article>
          )}

          {(enterprise_cards || page_section) && (
            <section className="relative">
              <article className="px-3 mx-auto max-w-7xl xl:px-5 md:px-6 sm:px-0 pt-9 sm:pt-0 ">
                <section className="enterpise_bg sm:mx-12 lg:mx-auto">
                  {enterprise_cards && (
                    <section className="pt-16 md:py-24">
                      <h2 className="text-center text-[#C5BBFF] uppercase sm:tracking-[0.1em] tracking-[0.78px] sm:font-medium font-semibold mb-4 text-xs sm:text-base">
                        {enterprise_cards?.primary?.section_label?.text}
                      </h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            enterprise_cards?.primary?.section_heading?.html,
                        }}
                        className="h3_gradient_dicom text-2xl sm:text-3.25xl sm:leading-11 tracking-tighter_3 font-semibold text-center lg:px-10 sm:px-3 mx-auto max-w-[233px] sm:max-w-md lg:max-w-lg"
                      />
                      {enterprise_cards?.primary?.section_link?.url && (
                        <section className="flex justify-center pt-7.5 sm:mb-16 mb-16">
                          <CustomLink
                            href={enterprise_cards?.primary?.section_link?.url}
                            target="_blank"
                            rel="Try Encord today noreferrer"
                            className={`text-white btn-dicom-nifti dicom-btn`}
                          >
                            Book a demo
                          </CustomLink>
                        </section>
                      )}

                      <div className="grid min-[1230px]:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-7 px-5 sm:px-6 md:px-12 lg:justify-items-start sm:justify-items-center">
                        {enterprise_cards?.items?.map((item, index) => (
                          <EnterpriseCard
                            key={index}
                            data={item}
                            customClass="enterpise_card"
                          />
                        ))}
                      </div>
                    </section>
                  )}
                  {page_section && (
                    <section className=" lg:mt-1.5 sm:mt-10 security_bg md:px-0 sm:px-6 sm:mb-12">
                      <RowsSection
                        isColReverse={true}
                        isReverse={true}
                        subHeading={page_section?.primary?.section_label?.text}
                        heading={page_section?.primary?.section_heading?.text}
                        description={
                          page_section?.primary?.section_description?.text
                        }
                        imgSrc={page_section?.primary?.section_image?.url}
                        isDicomNiftiPage={true}
                      />
                    </section>
                  )}
                </section>
              </article>
            </section>
          )}

          {better_model && (
            <section className="relative">
              <div className="absolute hidden better_model_gradient lg:top-72 lg:-left-28 top-[380px] -left-32 sm:block" />
              {/* <div className="absolute z-0 hidden better_model_elipse right-10 top-48 lg:block" /> */}
              <div className="absolute z-0 lg:bottom-32 sm:-bottom-5 -bottom-0 lg:-left-60 sm:-left-1/2 -left-3/4 sm:rotate-0 -rotate-2">
                <DicomFooter />
              </div>
              <article className="relative px-5 mx-auto max-w-7xl xl:px-5 md:px-6 sm:px-0 lg:pt-16 sm:pt-12">
                <section className="xl:px-0 sm:px-6">
                  <BetterModels
                    heading={better_model?.primary?.section_heading?.text}
                    description={
                      better_model?.primary?.section_description?.text
                    }
                    ctaHeading={_data?.cta_heading?.text}
                    ctaDescription={_data?.cta_description?.text}
                    modelTypes={better_model?.items}
                    pageName="dicom-nifti"
                  />
                </section>
              </article>
            </section>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default DicomNifti;

export const query = graphql`
  {
    allPrismicTrustedBrandsLogos(
      filter: { uid: { eq: "dicom-nifti-page-logos" } }
    ) {
      nodes {
        data {
          body {
            ... on PrismicTrustedBrandsLogosDataBodyTrustedBrandsList {
              id
              primary {
                label {
                  text
                }
              }
              items {
                brand_image {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicDicomNifti {
      data {
        cta_description {
          html
          text
        }
        cta_heading {
          html
          text
        }
        hero_section_video {
          url
        }
        meta_description {
          html
          text
        }
        meta_heading {
          html
          text
        }
        page_description {
          html
          text
        }
        page_heading {
          html
          text
        }
        body {
          ... on PrismicDicomNiftiDataBodyRepeatableCards {
            id
            slice_type
            items {
              section_description {
                text
                html
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_video {
                url
              }
            }
            primary {
              section_heading {
                html
                text
              }
              section_label {
                html
                text
              }
              section_link {
                url
              }
            }
          }
          ... on PrismicDicomNiftiDataBodyPageSection {
            id
            slice_type
            primary {
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicDicomNiftiDataBodyFeatureCards {
            id
            slice_type
            items {
              card_heading {
                html
                text
              }
              card_image {
                alt
                url
                dimensions {
                  height
                  width
                }
                gatsbyImageData(placeholder: BLURRED)
              }
              card_video {
                url
              }
            }
            primary {
              section_heading {
                html
                text
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicDicomNiftiDataBodyG2Rating {
            id
            slice_type
            items {
              feature_text {
                html
                text
              }
              icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              feature_icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
            primary {
              section_heading {
                html
                text
              }
              section_label {
                html
                text
              }
              section_link {
                url
              }
            }
          }
          ... on PrismicDicomNiftiDataBodyBetterModel {
            id
            slice_type
            items {
              section_feature_description {
                html
                text
              }
              section_feature_heading {
                html
                text
              }
              section_feature_icon {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
            }
            primary {
              section_heading {
                html
                text
              }
            }
          }
          ... on PrismicDicomNiftiDataBodyEnterpriseCards {
            id
            slice_type
            items {
              card_heading {
                html
                text
              }
              card_icon {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              card_image {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              card_video {
                url
              }
            }
            primary {
              section_heading {
                html
                text
              }
              section_label {
                html
                text
              }
              section_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicDicomNifti?.data || {};

  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
